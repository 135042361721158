import request from '@/utils/request'
const serivceBase = require('@/config/serviceConfig')

// 同步兑换码-前置校验
export function syncPreCheck(data) {
  return request({
    url: `${serivceBase.IMS}/redeemCodeStream/syncPreCheck`,
    method: 'post',
    data
  })
}
// 兑换码同步兑换
export function syncVerify(data) {
  return request({
    url: `${serivceBase.IMS}/redeemCodeStream/syncVerify`,
    method: 'post',
    data
  })
}
// 发送短信
export function sendVerifyCode(data) {
  return request({
    url: `${serivceBase.IMS}/redeemCodeStream/sendVerifyCodeForSync`,
    method: 'post',
    data
  })
}
// 兑换码列表查询
export function listRedeemCode(data) {
  return request({
    url: `${serivceBase.IMS}/redeemCodeStream/listRedeemCode`,
    method: 'post',
    data
  })
}
